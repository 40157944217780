.container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
}

@media only screen and (min-width: 601px) {
    .container {
        width: 90%;
    }
}

@media only screen and (min-width: 993px) {
    .container {
        width: 90%;
    }
}

/* Modular Table edits */
.tabulator{
    font-size: 14px;
}

.tabulator .tabulator-header{
    border-bottom: 2px solid #3f3f3f;
    margin-bottom: 4px;
    padding-left: 0px;
}

.tabulator .tabulator-header{
    color: #3f3f3f;
    font-weight: 500;
}

.tabulator-row .tabulator-cell:first-child {
    border-left: none; 
}
.tabulator-row:nth-child(even) {
    background-color: #00bfa5; 
}

.tabulator-row:nth-child(even) .tabulator-cell {
    background-color: #fff; 
}
.tabulator-row:nth-child(even) .tabulator-cell:first-child {
    border-left: none; 
}

.tabulator-row.tabulator-group {
    min-width: 100%;
    margin-bottom: 2px;
    border-bottom: 2px solid #00bfa5;
    border-top: 2px solid #00bfa5;
    border-right: none;
    background: #b4f6ed; 
}
.tabulator-row.tabulator-group span {
    color: #00bfa5; 
}

.tabulator-header-filter > input{
    padding: 1px 4px !important;
    margin: 0 0 5px 0 !important;
    background: #f9f9f9 !important;
    border-radius: 10px !important;
    font-size: 14px !important;
    height: 2em !important;
}